import { useEffect, useState } from "react";
import classNames from "classnames";
import { BookingFormType, BookingType, FormBookingType} from "../../../redux/types/bookingTypes";
import SelectComponent from "../../SelectComponent";
import BookingSectionTitle from "../BookingSectionTitle";
import PersonCount from "../PersonCount";
import { COMMENT_REQ, CURRENCY, priceMask, summMask } from "./../../../utils/constants";
import {subMonths, addYears, addDays, startOfDay, endOfDay, format} from 'date-fns';
import { createObjectList, statusListOption, timeListOption } from "../functions";
import Input from "../../Input";

import { ButtonType, InputType, OptionsListType } from "../../../utils/@globalTypes";
import Checkbox from "../../Checkbox";
import Tooltip from "../../Tooltip";
import Calendar from "../../Calendar";
import BookingLineTitle from "../BookingLineTitle";
import styles from "./BookingObjectBlock.module.scss";
import SelectComponentV2 from "../../SelectComponentV2";
import {
  ANIMALINFO_REQ
} from "./../../../utils/constants";
import { getCurrentRentalObject, RentalObjectSelectors } from "../../../redux/reducers/rentalObjectSlice";
import { useDispatch, useSelector } from "react-redux";
import { BookingSelectors } from "../../../redux/reducers/bookingSlice";
import Button from "../../Button";
import { FormikProps, useFormikContext } from "formik";
import { priceCalculator } from "../../../utils/priceCalculator";
import { TariffsSelectors } from "../../../redux/reducers/tariffsSlice";
import { TarifTypes } from "../../../redux/types/@types";
import { PricesSelectors } from "../../../redux/reducers/pricesSlice";

type ObjectblockProps ={
  windowType?:BookingFormType,
  isLast?:boolean,
  formik:FormikProps<FormBookingType>,
  booking?:BookingType   
}
const BookingObjectBlock = ({
    windowType=BookingFormType.view, 
    isLast=false,
    formik,
    booking   
}:ObjectblockProps) => {  
  const { setFieldValue } = useFormikContext();
  const {handleChange, values, errors, setFieldTouched, touched, validateField, initialValues} = formik;
  const currentTariff = useSelector(TariffsSelectors.getCurrentTarif)
  const dispatch = useDispatch();  
  const currentPrices = useSelector(PricesSelectors.getPrices);
  const currentCertainDayPrices = useSelector(PricesSelectors.getCertainDayPrices);
  const objects = useSelector(BookingSelectors.getChessObjects); 
  const optionList = objects?createObjectList(objects):[];  
  const statusListOptionForCreation:OptionsListType = currentTariff !== TarifTypes.FREE? 
   statusListOption.filter(item=>item.value!=="cld")
   : statusListOption.filter(item=>item.value ==="cfm" || item.value ==="awt")
  
  const [timeList, setTimeList] = useState(timeListOption);
  const current_object = useSelector(RentalObjectSelectors.getCurrentRentalObject) 
  const commentView = formik.values.owner_comment.length>0?formik.values.owner_comment: "Дополнительных комментариев нет";

  const bookingList = useSelector(BookingSelectors.getBookingList)
  
  const currentBookings = bookingList.map(item=>item)
  .filter(booking=> booking.rental_object=== current_object?.id && new Date(booking.check_out_date) > new Date())
  .sort((a,b)=>new Date(a.check_in_date).getTime()-new Date(b.check_in_date).getTime()) 

  useEffect(() => {
    if(booking){
      formik.setFieldValue('paid', booking.paid);
      formik.setFieldValue('total', booking.total);
      formik.setFieldValue('rest', (Number(booking.total)-Number(booking.paid)).toString());      
    }
  }, []); 
  
  useEffect(()=> {
    if(values.rental_object){
      dispatch(getCurrentRentalObject(Number(values.rental_object)))            
    }
  }, [values.rental_object]);

  useEffect(()=> {
    if(current_object&&values.rental_object&&!initialValues.check_out_time){
      setFieldValue("check_out_time", current_object.booking_setting.check_out)
           
    }  
  }, [current_object]);  
  useEffect(()=> {
    if(current_object&&values.rental_object&&!initialValues.check_in_time){
      setFieldValue("check_in_time", current_object.booking_setting.check_in)      
    }  
  }, [current_object]);  
  
  // const currentYear = new Date().getFullYear();
    
  const handleClickSumm =() => {  // пересчет суммы брони при клике на кнопку
    
    if(values.check_in_date &&
      values.check_out_date &&
      current_object && 
      !errors.check_in_date&&
      !errors.check_out_date  
    ){
      setFieldTouched("total")
      const period:Date[]=[];     // массив из дат, входящих в бронь   
      for(let i=values.check_in_date; i<values.check_out_date; i=addDays(new Date(i),1)){      
        period.push(i)            
      }   
      const summ = priceCalculator(current_object.price, currentCertainDayPrices, period).summ
      setFieldValue("total",summ.toFixed(2).toString())    
    }    
  } 
  
  const animalInfoView = !values.has_animals? "Нет": values.animals_info;
  const today = new Date();
  const startCalendar = subMonths(new Date(), 6);
  const endCalendar = addYears(new Date(), 1); 

  useEffect(() => {
    if(touched.rental_object){
      validateField("rental_object")
    };
  }, [touched, values]);

  useEffect(() => {
    if(touched.status){
      validateField("status")
    };
  }, [touched, values]);

  useEffect(() => {
    if(touched.animals_info){
      validateField("animals_info")
    };
  }, [touched, values]);

  useEffect(() => {
    if(!values.has_animals&&values.animals_info.length>0){
      setFieldValue("animals_info", "")
    };
  }, [touched, values]);

  useEffect(() => {
    if(touched.check_in_date||touched.check_out_date){
      validateField("check_in_date");
      validateField("check_out_date")
    };
  }, [touched, values]); 

  useEffect(() => {
    if(touched.check_in_time){
      validateField("check_in_time")
    };
  }, [touched, values]);
  useEffect(() => {
    if(touched.check_out_time){
      validateField("check_out_time")
    };
  }, [touched, values]);

  useEffect(() => {
    if(values.check_in_date && values.check_in_date>values.check_out_date){
      setFieldValue("check_out_date", addDays(values.check_in_date,1))
    };
  }, [touched, values]); 
  
  useEffect(()=> {  // пересчет остатка суммы к оплате
    if(touched.total||touched.paid){
    setFieldValue("rest",((values.total?Number(values.total):0)-(values.paid?Number(values.paid):0)).toFixed(2).toString()); 
    }     
  },[touched, values]) 

  useEffect(() => {
    if(touched.total||touched.paid){
      validateField("total");
      validateField("paid");
      validateField("rest");
    };
  }, [touched, values]);  
  const createdDate = booking?.created_at && format(new Date(booking?.created_at), "dd.MM.yyyy") 
  
  return (
    <div className={styles.wrap}>
      <BookingSectionTitle title="Информация об объекте" created={createdDate}/>
      <div className={styles.container}>
        <div className={classNames(styles.line, styles.firstLine)}>
          <div className={styles.column}>
            <SelectComponent
                name="rental_object"
                id="rental_object"
                title={windowType===BookingFormType.view ? "Объект" : "Объект*"}
                optionsList={optionList}
                currentValue={(values.rental_object&&values.rental_object.toString())||""}
                // isSearchable={windowType===BookingFormType.view ? false : true}
                isDisabled={windowType===BookingFormType.view||isLast ? true : false}
                setSelecValue={handleChange("rental_object")}
                isBooking={true}
                onBlur={() => setFieldTouched("rental_object")}
                errText={errors.rental_object}                           
            />
          </div>
          <div className={styles.column}>
            <SelectComponent
                  name="status"
                  id="status"
                  title={isLast ? "Статус":"Статус*"}
                  optionsList={windowType===BookingFormType.new ? statusListOptionForCreation : statusListOption}
                  currentValue={values.status}
                  setSelecValue={handleChange("status")}
                  isBooking={true}
                  onBlur={() => setFieldTouched("status")}
                  defaultValueId={windowType===BookingFormType.new ? 0: undefined}
                  isDisabled={isLast ? true : false}
                  errText={errors.status}
                />
          </div>
        </div>        
        
        <div className={classNames(styles.line, styles.secondLine)}>
          <div className={classNames(styles.column, styles.dateBlock, windowType!==BookingFormType.view&&styles.edit)}>
          
            <div>
              <BookingLineTitle title={windowType===BookingFormType.view ? "Дата заезда" : "Дата заезда*"}/>
              <Calendar
                name="check_in_date"
                id="check_in_date"
                disabled={windowType===BookingFormType.view ? true: false }
                placeholderText="Выберите дату заезда"
                selectedDate={values.check_in_date}
                onChange={(val) => {
                  setFieldValue("check_in_date", val)}}
                isSmall={true}     
                minDate={windowType===BookingFormType.edit ? startCalendar : today} 
                maxDate={endCalendar}     
                isError={!!errors.check_in_date}     
                onBlur={() => setFieldTouched("check_in_date")}
                errText={errors.check_in_date}
                // highlightDates={currentBookings}
              />             
            </div>

            <div className={styles.out}>
              <BookingLineTitle title={windowType===BookingFormType.view ? "Дата выезда" : "Дата выезда*"}/>
              <Calendar
                  name="check_out_date"
                  id="check_out_date"
                  disabled={windowType===BookingFormType.view ? true: false }
                  placeholderText="Выберите дату выезда"
                  selectedDate={values.check_out_date}
                  onChange={(val) => {
                    setFieldValue("check_out_date", val)}}
                  isSmall={true} 
                  minDate={(values.check_in_date&&addDays(values.check_in_date,1)) || addDays(today,1)}  
                  maxDate={endCalendar}   
                  isError={!!errors.check_out_date} 
                  onBlur={() => setFieldTouched("check_out_date")}     
                  errText={errors.check_out_date}
                />               
              </div>
          </div>
          <div className={classNames(styles.column, styles.dateBlock, windowType!==BookingFormType.view&&styles.edit)}>
          <div>
              <BookingLineTitle title={windowType===BookingFormType.view ? "Время заезда" : "Время заезда*"}/>
              {windowType===BookingFormType.view && 
                <div className={styles.viewTime}>
                  {values.check_in_time &&"c "+ values.check_in_time}
                </div>}
              {windowType!==BookingFormType.view && 
              <SelectComponentV2
                title={values.check_in_time? values.check_in_time: '-'}
                placeholder=""
                currentValue={values.check_in_time}
                setSelecValue={handleChange("check_in_time")}
                optionsList={timeList}
                onBlur={() => setFieldTouched("check_in_time")}
                errText={errors.check_in_time}
                isBooking
                isTime
              />
              
              }  
            </div>
            <div className={styles.out}>
              <BookingLineTitle title={windowType===BookingFormType.view ? "Время выезда" : "Время выезда*"}/>
              {windowType===BookingFormType.view && 
                <div className={styles.viewTime}>
                  {values.check_out_time &&"до "+ values.check_out_time}
                </div>
              }                
              {windowType!==BookingFormType.view && 
                <SelectComponentV2
                title={values.check_out_time? values.check_out_time: '-'}
                placeholder=""
                currentValue={values.check_out_time}
                setSelecValue={handleChange("check_out_time")}
                optionsList={timeList}
                onBlur={() => setFieldTouched("check_out_time")}
                errText={errors.check_out_time}
                isBooking
                isTime
              />
              }                
              </div>
          </div>
        </div>
        <div className={styles.column}>
          <PersonCount 
            adult={values.adult||1} 
            children={values.children }
            windowType={windowType}
            setAdult = {(val) => {
              setFieldValue("adult", val)}}
            setKids = {(val) => {
              setFieldValue("children", val)}}
          />
        </div>
        <div className={styles.animalBlock}>
          <div className={styles.animalLine}>
            <span className={classNames(styles.animalTitle, windowType===BookingFormType.view&&styles.disabled)}>
              Есть ли животные? {values.has_animals&&`*`} 
            </span>

            {windowType!==BookingFormType.view&&
            <div className={styles.checkbox}>
              <Checkbox              
                checked={values.has_animals}
                label=""
                name="has_animals"
                onClick={() => {
                  setFieldValue("has_animals", !values.has_animals)}}       
              />
              </div>}
            {windowType!==BookingFormType.view&&values.has_animals&&
            <div className={styles.tooltip}>
              <Tooltip
                requirementsText={ANIMALINFO_REQ}  
                error={!!errors.animals_info?.length}          
              />
            </div>}
          </div>
          {windowType===BookingFormType.view&&
            <div className={styles.animalInfo}>
              {animalInfoView}
            </div>
          }
          {windowType!==BookingFormType.view&&formik.values.has_animals&&
            <Input
              id="animals_info"
              name="animals_info"
              type={InputType.TEXTAREA}
              value={values.animals_info}
              placeholder="Количество, вид, возраст"
              onChange={handleChange("animals_info")}
              isBooking={true}
              onBlur={() => setFieldTouched("animals_info")}   
              errText={errors.animals_info}
              maxLength={100}
            />
          }
        </div>
        
        <div className={styles.priceBlock}>
          <div className={styles.summBlock}>
            <div className={styles.totalLine}>
              <span className={styles.totalTitle}>
                Общая стоимость за выбранный период*
              </span>
              <div className={styles.totalTitleMob}>
                Общая стоимость*
              </div>
              <div className={styles.inputLine}>
                <div className={styles.inputsumm}> 
                  <Input
                    id="total"
                    placeholder="0.00"
                    type={InputType.MASK}
                    mask={priceMask}
                    value={values.total||""}
                    onChange={handleChange("total")}
                    onBlur={() => setFieldTouched("total")}
                    isBooking={true}
                  />                
                </div>
                <span className={styles.currency}>
                  {CURRENCY}
                </span>
              </div>
            </div>
            {errors.total&&<div className={classNames(styles.errorText, styles.summError)}>{errors.total}</div>}
         
              <ul className={styles.list} >
                <li className={styles.payLine}>
                  <span className={styles.marker}>&#8226;</span>
                  <div className={styles.payTitle}>
                    Оплаченная сумма
                  </div>
                  <div className={styles.inputLine}>
                    <span className={styles.inputsumm}>   
                      <Input
                        id="paid"
                        placeholder="0.00"
                        type={InputType.MASK}
                        mask={priceMask}
                        value={values.paid||""}
                        onChange={handleChange("paid")}
                        onBlur={() => setFieldTouched("paid")}
                        isBooking={true}
                      />                   
                    </span>
                    <span className={styles.currency}>
                    {CURRENCY}
                    </span>  
                  </div>              
                </li>
                {errors.paid&&<span className={classNames(styles.errorText, styles.summError)}>{errors.paid}</span>}
                <li className={styles.payLine}>
                  <span className={styles.marker}>&#8226;</span>
                  <span className={classNames(styles.payTitle, styles.rest)}>
                    Сумма, которая осталась к оплате
                  </span>
                  <div className={styles.restMob}>
                    Осталось к оплате
                  </div>
                  <div className={styles.inputLine}>
                    <span className={styles.inputsumm}>   
                      <Input
                        id="rest"
                        placeholder="0.00"
                        type={InputType.MASK}
                        mask={summMask}
                        value={values.rest||""}
                        onChange={handleChange("rest")}
                        disabled={true}
                        onBlur={() => setFieldTouched("paid")}
                        isBooking={true}
                      />                   
                    </span>
                    <span className={styles.currency}>
                    {CURRENCY}
                    </span>
                  </div>
                </li>
              </ul>

          </div>
          {currentTariff!==TarifTypes.FREE&&
          <div className={styles.btnSumm}>
            <Button 
                title="Пересчитать" 
                type={ButtonType.PRIMARY_SMALL} 
                onClick={handleClickSumm}
                disabled= {windowType===BookingFormType.view ? true: false}                
              />
          </div>}
        </div>
      </div>
      <BookingSectionTitle title="Комментарий владельца"/>
      <div className={styles.container}>
      {windowType===BookingFormType.view&&
            <div className={styles.commentView}>
              {commentView}
            </div>
          }
      {windowType!==BookingFormType.view&&
        <Input
          title="Комментарий владельца"
          name="owner_comment"
          id="owner_comment"
          type={InputType.TEXTAREA}
          value={values.owner_comment}
          placeholder="Введите текст"
          onChange={handleChange("owner_comment")}
          isBooking={true}
          className={"comment"}
          onBlur={() => setFieldTouched("owner_comment")}
          errText={errors.owner_comment}
          requirementsText={COMMENT_REQ}
          maxLength={550}          
        />}
      </div>
    </div>
  )
}

export default BookingObjectBlock ;