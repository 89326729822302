import React, { FC, useEffect, useLayoutEffect, useMemo, useState } from "react";
import styles from "./RentalBasicSettings.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Input from "../../../../components/Input";
import SelectComponent from "../../../../components/SelectComponent";
import {
  ButtonType,
  LocalFeatureType,
  FieldTypes,
  FormattedImageListType,
  InputType,
  TabsNames,
} from "../../../../utils/@globalTypes";
import Button from "../../../../components/Button";
import { RoutesList } from "../../../../App";
import {
  RentalObjectSelectors,
  updateRentalObject,
  updateRentalObjectAllBasicSettings,
  updateRentalObjectImages,
} from "../../../../redux/reducers/rentalObjectSlice";
import {
  OBJECT_NAME_REQ,
  RENTAL_DESCRIPTION_REQ,
  RENTAL_OBJECT_IMAGES_REQ,
} from "../../../../utils/constants";
import { banDoubleSpace, setFieldErrorText } from "../../../../utils/functions";
import FeatureCard from "../../../../components/FeatureCard";
import ImageMultipleComponent from "../../../../components/ImageMultipleComponent";
import { FeatureType, UpdateRentalObjectData } from "../../../../redux/types/rentalObjectTypes";

export type RentalBasicSettingsProps = {
  // featuresList: FeaturesList;
  // currentRentalObject: RentalObject | null;
  // rentalId: number;
  // mainId: number;
};

const RentalBasicSettings: FC<RentalBasicSettingsProps> = (
  {
    // featuresList,
    // currentRentalObject,
    // rentalId,
    // mainId,
  }
) => {
  const dispatch = useDispatch();
  const { mainId, rentalId } = useParams();

  const rentalObjectTypes = useSelector(RentalObjectSelectors.getRentalObjectTypes);
  const rentalObjectStatuses = useSelector(RentalObjectSelectors.getRentalObjectStatuses);
  const currentRentalObject = useSelector(RentalObjectSelectors.getCurrentRentalObject);
  const currentFeaturesList = useSelector(RentalObjectSelectors.getCurrentFeaturesList);
  const currentRentalImages = useSelector(RentalObjectSelectors.getCurrentImagesList);

  const [rentalObjName, setRentalObjName] = useState("");
  const [rentalObjType, setRentalObjType] = useState("");
  const [rentalObjStatus, setRentalObjStatus] = useState("");
  const [maxPlaces, setMaxPlaces] = useState("");
  const [rentalObjDescription, setRentalObjDescription] = useState("");

  const [featuresData, setFeaturesData] = useState<LocalFeatureType[]>([]);
  // const [features, setFeatures] = useState<string[]>([]);
  // const [currentFeature, setCurrentFeature] = useState("");

  // const [images, setImages] = useState<FormattedImageListType>([]);
  const [newImages, setNewImages] = useState<FormattedImageListType>([]);
  const [removedImages, setRemovedImages] = useState<FormattedImageListType>([]);
  const [newImagesError, setNewImagesError] = useState("");

  // const [feature, setFeature] = useState("");
  // const [featureLogo, setFeatureLogo] = useState<ImageListType>([]);

  const [rentalObjNameError, setRentalObjNameError] = useState("");
  const [rentalObjTypeError, setRentalObjTypeError] = useState("");
  const [rentalObjDescriptionError, setRentalObjDescriptionError] = useState("");
  const [maxPlacesError, setMaxPlacesError] = useState("");

  const [rentalObjNameTouched, setRentalObjNameTouched] = useState(false);
  const [rentalObjTypeTouched, setRentalObjTypeTouched] = useState(false);
  const [rentalObjDescriptionTouched, setRentalObjDescriptionTouched] = useState(false);
  const [maxPlacesTouched, setMaxPlacesTouched] = useState(false);
  const [featuresTouched, setFeaturesTouched] = useState(false);

  const oldFeaturesList: FeatureType[] = currentFeaturesList.map((item) => ({
    name: item.name,
    logo: item.logo,
  }));
  const newFeaturesList: FeatureType[] = featuresData.map((item) => ({
    name: item.feature.name,
    logo: item.feature.logo,
  }));

  const onAddFeatureBtnClick = () => {
    rentalId &&
      setFeaturesData((prevState) => [
        ...prevState,
        {
          isNew: true,
          isValid: true,
          feature: {
            id: prevState.length * Math.random(),
            name: "",
            logo: "",
          },
        },
      ]);
  };

  const onDeleteFeatureBtnClick = (value: number) => () => {
    setFeaturesData((prevState) =>
      prevState.filter((feature, index) => {
        return index !== value;
      })
    );
  };

  useEffect(() => {
    // console.log(newImages);
  }, [newImages]);

  useEffect(() => {
    // console.log(removedImages);
  }, [removedImages]);

  // useEffect(() => {
  //   console.log(featuresTouched);
  // }, [featuresTouched]);

  const onSaveObjectBtnClick = () => {
    setRentalObjNameTouched(true);
    setRentalObjTypeTouched(true);
    setRentalObjDescriptionTouched(true);
    setMaxPlacesTouched(true);
    setFeaturesTouched(true);

    if (
      rentalId &&
      !!currentRentalObject &&
      !rentalObjNameError &&
      !rentalObjDescriptionError &&
      !rentalObjTypeError &&
      !maxPlacesError &&
      !newImagesError &&
      !featuresData.some(
        (feature) =>
          (feature.feature.logo === "star" || feature.feature.logo === "diamond") &&
          !feature.feature.name.length
      ) &&
      !newImages.some((image) => image.value.error) &&
      rentalObjName.length > 0 &&
      rentalObjDescription.length > 0 &&
      rentalObjType.length > 0 &&
      maxPlaces.length > 0
    ) {
      const data: UpdateRentalObjectData = {};
      let images: FormattedImageListType = [];

      const { name, description, status, object_type, max_places } = currentRentalObject;

      name !== rentalObjName && (data.name = rentalObjName);
      description !== rentalObjDescription && (data.description = rentalObjDescription);
      max_places !== +maxPlaces && (data.max_places = +maxPlaces);
      status !== rentalObjStatus && (data.status = rentalObjStatus);
      object_type.id !== +rentalObjType && (data.object_type = +rentalObjType);
      JSON.stringify(oldFeaturesList) !== JSON.stringify(newFeaturesList) &&
        (data.features = newFeaturesList);
      JSON.stringify(currentRentalImages.map((item) => item.image)) !==
        JSON.stringify(newImages.map((item) => item.value.image)) && (images = newImages);

      dispatch(
        updateRentalObjectAllBasicSettings({
          info: Object.keys(data).length
            ? {
                id: +rentalId,
                data,
              }
            : undefined,
          images:
            removedImages.length || images.length
              ? {
                  rental_object: +rentalId,
                  images,
                  removedIdList: removedImages.map((image) => image.id),
                  clearRemovedListCallback: () => setRemovedImages([]),
                }
              : undefined,
        })
      );

      setNewImages([]);
    }
  };

  useLayoutEffect(() => {
    if (currentRentalObject) {
      const { name, object_type, status, max_places, description } = currentRentalObject;

      setRentalObjName(name);
      description && setRentalObjDescription(description);
      setRentalObjStatus(status);
      setRentalObjType(object_type.id.toString());
      max_places && setMaxPlaces(max_places.toString());
      setFeaturesData(
        currentFeaturesList.map((item) => ({
          isNew: false,
          isValid: true,
          feature: item,
        }))
      );
      setNewImages(
        currentRentalImages.map((item) => ({
          id: item.id,
          value: {
            image: item.image,
            error: "",
            isNew: false,
          },
        }))
      );
    }
    console.log(currentRentalImages);
  }, [currentRentalObject, currentFeaturesList, currentRentalImages]);

  // Validation

  // Object name

  useEffect(() => {
    setFieldErrorText(
      rentalObjNameTouched,
      rentalObjName,
      setRentalObjNameError,
      FieldTypes.OBJECT_NAME,
      true
    );
  }, [rentalObjNameTouched, rentalObjName]);

  // Object type

  useEffect(() => {
    setFieldErrorText(
      rentalObjTypeTouched,
      rentalObjType,
      setRentalObjTypeError,
      FieldTypes.DEFAULT,
      true
    );
  }, [rentalObjTypeTouched, rentalObjType]);

  // Max places

  useEffect(() => {
    setFieldErrorText(maxPlacesTouched, maxPlaces, setMaxPlacesError, FieldTypes.DEFAULT, true);
  }, [maxPlacesTouched, maxPlaces]);

  // Description

  useEffect(() => {
    setFieldErrorText(
      rentalObjDescriptionTouched,
      rentalObjDescription,
      setRentalObjDescriptionError,
      FieldTypes.RENTAL_DESCRIPTION,
      true
    );
  }, [rentalObjDescriptionTouched, rentalObjDescription]);

  const isValid = useMemo(() => {
    return (
      !rentalObjNameError &&
      !rentalObjDescriptionError &&
      !rentalObjTypeError &&
      !maxPlacesError &&
      !featuresData.some((feature) => feature.isValid === false) &&
      !newImages.some((image) => image.value.error)
    );
  }, [
    rentalObjNameError,
    rentalObjDescriptionError,
    rentalObjTypeError,
    maxPlacesError,
    featuresData,
    newImages,
  ]);

  const isFieldsChanged = useMemo(() => {
    if (!!currentRentalObject) {
      const { name, description, status, object_type, max_places } = currentRentalObject;

      return (
        name !== rentalObjName ||
        description !== rentalObjDescription ||
        status !== rentalObjStatus ||
        object_type.id !== +rentalObjType ||
        max_places !== +maxPlaces ||
        JSON.stringify(oldFeaturesList) !== JSON.stringify(newFeaturesList) ||
        JSON.stringify(currentRentalImages.map((item) => item.image)) !==
          JSON.stringify(newImages.map((item) => item.value.image))
      );
    }
  }, [
    rentalObjName,
    rentalObjDescription,
    rentalObjStatus,
    rentalObjType,
    maxPlaces,
    currentRentalObject,
    oldFeaturesList,
    newFeaturesList,
    newImages,
    currentRentalImages,
  ]);

  return (
    <>
      <div className={styles.settingsWrapper}>
        <div>
          <h3 className={styles.contentTitle}>Объект</h3>
          <div className={styles.objWrapper}>
            <div className={styles.objTopWrapper}>
              <Input
                value={rentalObjName}
                title="Наименование сдаваемого объекта*"
                placeholder="Введите наименование объекта"
                type={InputType.TEXT}
                onChange={setRentalObjName}
                errText={rentalObjNameError}
                onBlur={setRentalObjNameTouched}
                requirementsText={OBJECT_NAME_REQ}
                customBanSymbols={banDoubleSpace}
              />
              <SelectComponent
                title="Вид сдаваемого объекта*"
                optionsList={rentalObjectTypes}
                currentValue={rentalObjType}
                setSelecValue={setRentalObjType}
                errText={rentalObjTypeError}
                isLoading={rentalObjectTypes.length === 0}
                onBlur={setRentalObjTypeTouched}
              />
              <SelectComponent
                title="Статус объекта*"
                optionsList={rentalObjectStatuses}
                currentValue={rentalObjStatus}
                setSelecValue={setRentalObjStatus}
                isLoading={rentalObjectStatuses.length === 0}
                // defaultValueId={rentalObjectStatuses.length > 0 ? 0 : undefined}
              />
              <Input
                value={maxPlaces}
                title="Максимальное количество мест*"
                placeholder="0"
                type={InputType.MASK}
                onChange={setMaxPlaces}
                errText={maxPlacesError}
                onBlur={setMaxPlacesTouched}
                mask={{
                  mask: Number,
                  thousandsSeparator: "",
                  min: 0,
                  max: 999,
                  scale: 0,
                }}
              />
            </div>
            <Input
              value={rentalObjDescription}
              title="Текстовое описание*"
              placeholder="Введите описание"
              type={InputType.TEXTAREA}
              onChange={setRentalObjDescription}
              errText={rentalObjDescriptionError}
              onBlur={setRentalObjDescriptionTouched}
              requirementsText={RENTAL_DESCRIPTION_REQ}
              className={styles.description}
            />
          </div>
        </div>
        <div>
          <h3 className={styles.contentTitle}>Удобства сдаваемого объекта</h3>
          <div className={styles.featuresWrapper}>
            {featuresData.length > 0 && rentalId && (
              <div className={styles.featuresList}>
                {featuresData.map((feature, index) => {
                  const {
                    feature: { name, id, logo },
                  } = feature;
                  return (
                    <FeatureCard
                      key={id}
                      featureIndex={index}
                      setFeatureData={setFeaturesData}
                      deleteHandler={onDeleteFeatureBtnClick}
                      isAllTouched={featuresTouched}
                      setIsAllTouched={setFeaturesTouched}
                      name={name}
                      logo={logo}
                    />
                  );
                })}
              </div>
            )}
            <Button
              title="Добавить"
              type={ButtonType.PRIMARY_SMALL}
              onClick={onAddFeatureBtnClick}
            />
          </div>
        </div>
        <ImageMultipleComponent
          title="Фото"
          // images={images}
          newImages={newImages}
          setNewImages={setNewImages}
          setRemovedImages={setRemovedImages}
          setError={setNewImagesError}
          errText={newImagesError}
          requirementsText={RENTAL_OBJECT_IMAGES_REQ}
          alt=""
          maxNumber={9}
          maxFileSize={2000000}
          acceptType={["jpg", "jpeg", "png"]}
          resolutionHeight={2000}
          resolutionWidth={2000}
        />
      </div>
      <div className={styles.mainBtnsWrapper}>
        <Button
          title="Сохранить"
          type={ButtonType.PRIMARY_SMALL}
          onClick={onSaveObjectBtnClick}
          disabled={!isValid || !isFieldsChanged}
          className={styles.mainBtn}
        />
        <Button
          title={isFieldsChanged ? "Отмена" : "Назад"}
          type={ButtonType.SECONDARY_SMALL}
          path={`${RoutesList.objectManagement.objectEditingChild.fullPath}/${mainId}/${TabsNames.OBJECT_EDITING}`}
          className={styles.mainBtn}
        />
      </div>
    </>
  );
};

export default RentalBasicSettings;
