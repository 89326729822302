import React, { FC, useEffect, useLayoutEffect, useMemo, useState } from "react";
import styles from "./BasicSettings.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { MainObjectSelectors, updateMainObject } from "../../../../redux/reducers/mainObjectSlice";
import { MainObject } from "../../../../redux/types/mainObjectTypes";
import {
  AddressType,
  ButtonType,
  FieldTypes,
  InputType,
  MessageTypes,
} from "../../../../utils/@globalTypes";
import { CopyIcon, Home2Icon } from "../../../../assets/icons";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import SelectComponent from "../../../../components/SelectComponent";
import { RoutesList } from "../../../../App";
import { AuthSelectors, setErrorResponeData } from "../../../../redux/reducers/authSlice";
import {
  ADDRESS_ERROR,
  ADDRESS_REQ,
  IMAGE_ERROR,
  LOCALITY_ERROR,
  LOCALITY_REQ,
  MAIN_OBJECT_IMAGE_REQ,
  OBJECT_NAME_ERROR,
  OBJECT_NAME_REQ,
  REGION_ERROR,
  REGION_REQ,
} from "../../../../utils/constants";
import { banDoubleSpace, getErrorText, setFieldErrorText } from "../../../../utils/functions";
import { ImageListType } from "react-images-uploading";
import ImageComponent from "../../../../components/ImageComponent";
import Icons from "../../../../components/Icons";
import { setMessage } from "../../../../redux/reducers/pageSlice";
import { API_HOST } from "../../../../utils/config";
import { TariffsSelectors } from "../../../../redux/reducers/tariffsSlice";
import { TarifTypes } from "../../../../redux/types/@types";

type BasicSettingsProps = {
  pageId: string;
  currentObject: MainObject | null;
};

const BasicSettings: FC<BasicSettingsProps> = ({ pageId, currentObject }) => {
  const dispatch = useDispatch();
  const bookingLink = `https://${
    API_HOST.includes("dev") ? "dev." : ""
  }client.vitaem.app/${pageId}`;

  const objectTypes = useSelector(MainObjectSelectors.getMainObjectTypesData);
  const countriesList = useSelector(MainObjectSelectors.getCountriesOptions);
  const errorResponseData = useSelector(AuthSelectors.getErrorResponseData);
  const currentTarif = useSelector(TariffsSelectors.getCurrentTarif)
  const [photo, setPhoto] = useState<string | null>("");
  const [newPhoto, setNewPhoto] = useState<ImageListType>([]);
  const [newPhotoError, setNewPhotoError] = useState("");
  const [bookingPhoto, setBookingPhoto] = useState<string | null>("");
  const [newBookingPhoto, setNewBookingPhoto] = useState<ImageListType>([]);
  const [newBookingPhotoError, setNewBookingPhotoError] = useState("");

  const [objectName, setObjectName] = useState("");
  const [objectType, setObjectType] = useState("");
  const [country, setCountry] = useState("");

  const [region, setRegion] = useState("");
  const [locality, setLocality] = useState("");
  const [address, setAddress] = useState("");

  const [objectNameError, setObjectNameError] = useState("");
  const [objectTypeError, setObjectTypeError] = useState("");
  const [countryError, setCountryError] = useState("");

  const [regionError, setRegionError] = useState("");
  const [localityError, setLocalityError] = useState("");
  const [addressError, setAddressError] = useState("");

  const [objectNameTouched, setObjectNameTouched] = useState(false);
  const [objectTypeTouched, setObjectTypeTouched] = useState(false);
  const [countryTouched, setCountryTouched] = useState(false);

  const [regionTouched, setRegionTouched] = useState(false);
  const [localityTouched, setLocalityTouched] = useState(false);
  const [addressTouched, setAddressTouched] = useState(false);

  const onSaveBtnClick = () => {
    setObjectNameTouched(true);
    setObjectTypeTouched(true);
    setCountryTouched(true);

    setRegionTouched(true);
    setLocalityTouched(true);
    setAddressTouched(true);

    if (
      !!currentObject &&
      !objectNameError &&
      !objectTypeError &&
      !countryError &&
      !regionError &&
      !localityError &&
      !addressError &&
      !newPhotoError &&
      !newBookingPhotoError &&
      objectName.length > 0 &&
      objectType.length > 0 &&
      region.length > 0 &&
      locality.length > 0 &&
      address.length > 0 &&
      country.length > 0
    ) {
      const formData = new FormData();

      const { object_type, name, country: objCountry, full_address } = currentObject;

      object_type.id !== +objectType && formData.append("object_type", objectType);
      name !== objectName && formData.append("name", objectName);
      objCountry !== country && formData.append("country", country);
      newPhoto.length > 0 && formData.append("photo", newPhoto[0].file as Blob);
      newBookingPhoto.length > 0 &&
        formData.append("booking_photo", newBookingPhoto[0].file as Blob);

      if (
        (full_address &&
          (full_address.region !== region ||
            full_address.locality !== locality ||
            full_address.address !== address)) ||
        !full_address
      ) {
        const newFullAddress: AddressType = {
          region,
          locality,
          address,
        };
        formData.append("full_address", JSON.stringify(newFullAddress));
      }

      dispatch(
        updateMainObject({
          data: formData,
          id: pageId,
        })
      );
    }
  };

  const copyLinkBtn = () => {
    navigator.clipboard.writeText(bookingLink);
    dispatch(
      setMessage({
        status: MessageTypes.POSITIVE,
        code: 200,
        message: "Скопировано в буфер обмена",
      })
    );
  };

  useLayoutEffect(() => {
    if (currentObject) {
      const { photo, name, object_type, country, full_address, booking_photo } = currentObject;

      setNewBookingPhoto([]);
      setBookingPhoto(booking_photo);
      setNewPhoto([]);
      setPhoto(photo);
      setObjectName(name);
      setObjectType(object_type.id.toString());
      setCountry(country);
      if (full_address) {
        setRegion(full_address.region);
        setLocality(full_address.locality);
        setAddress(full_address.address);
      }
    }
  }, [currentObject]);

  useEffect(() => {
    if (errorResponseData) {
      errorResponseData.photo &&
        setNewPhotoError(
          getErrorText(errorResponseData.photo[0], IMAGE_ERROR, FieldTypes.MAIN_OBJECT_PHOTO)
        );

      errorResponseData.booking_photo &&
        setNewBookingPhotoError(
          getErrorText(
            errorResponseData.booking_photo[0],
            IMAGE_ERROR,
            FieldTypes.MAIN_OBJECT_BOOKING_PHOTO
          )
        );

      errorResponseData.name &&
        setObjectNameError(
          getErrorText(errorResponseData.name[0], OBJECT_NAME_ERROR, FieldTypes.OBJECT_NAME)
        );

      errorResponseData.region &&
        setRegionError(getErrorText(errorResponseData.region[0], REGION_ERROR, FieldTypes.REGION));

      errorResponseData.locality &&
        setLocalityError(
          getErrorText(errorResponseData.locality[0], LOCALITY_ERROR, FieldTypes.LOCALITY)
        );

      errorResponseData.address &&
        setAddressError(
          getErrorText(errorResponseData.address[0], ADDRESS_ERROR, FieldTypes.ADDRESS)
        );

      dispatch(setErrorResponeData(null));
    }
  }, [errorResponseData]);

  // OBJECT FIELDS VALIDATION
  // ObjectName

  useEffect(() => {
    setFieldErrorText(
      objectNameTouched,
      objectName,
      setObjectNameError,
      FieldTypes.OBJECT_NAME,
      true
    );
  }, [objectNameTouched, objectName]);

  // ObjectType

  useEffect(() => {
    setFieldErrorText(objectTypeTouched, objectType, setObjectTypeError, FieldTypes.DEFAULT, true);
  }, [objectTypeTouched, objectType]);

  // Country

  useEffect(() => {
    setFieldErrorText(countryTouched, country, setCountryError, FieldTypes.DEFAULT, true);
  }, [countryTouched, country]);

  // Full address

  useEffect(() => {
    setFieldErrorText(regionTouched, region, setRegionError, FieldTypes.REGION, true);
  }, [regionTouched, region]);

  useEffect(() => {
    setFieldErrorText(localityTouched, locality, setLocalityError, FieldTypes.LOCALITY, true);
  }, [localityTouched, locality]);

  useEffect(() => {
    setFieldErrorText(addressTouched, address, setAddressError, FieldTypes.ADDRESS, true);
  }, [addressTouched, address]);

  const isValid = useMemo(() => {
    return (
      !objectTypeError &&
      !objectNameError &&
      !countryError &&
      !newPhotoError &&
      !newBookingPhotoError &&
      !addressError &&
      !regionError &&
      !localityError
    );
  }, [
    objectTypeError,
    objectNameError,
    countryError,
    newPhotoError,
    newBookingPhotoError,
    addressError,
    regionError,
    localityError,
  ]);

  const isFieldsChanged = useMemo(() => {
    if (!!currentObject) {
      const { object_type, name, country: objCountry, full_address } = currentObject;
      return (
        object_type.id !== +objectType ||
        name !== objectName ||
        objCountry !== country ||
        newPhoto.length > 0 ||
        newBookingPhoto.length > 0 ||
        (full_address &&
          (full_address.region !== region ||
            full_address.locality !== locality ||
            full_address.address !== address)) ||
        (!full_address && (address.length > 0 || region.length > 0 || locality.length > 0))
      );
    }
  }, [
    currentObject,
    objectType,
    objectName,
    country,
    address,
    region,
    locality,
    newPhoto,
    newBookingPhoto,
  ]);

  return (
    <>
      <div className={styles.basicSettingsWrapper}>
        <div className={styles.bookingPhotoWrapper}>
          <h3 className={styles.contentTitle}>
            Фото <span>(для букинга)</span>
          </h3>
          <ImageComponent
            newImage={newBookingPhoto}
            image={bookingPhoto}
            setNewImage={setNewBookingPhoto}
            setImage={setBookingPhoto}
            setError={setNewBookingPhotoError}
            errText={newBookingPhotoError}
            noImageIcon={<Home2Icon />}
            maxFileSize={2000000}
            acceptType={["jpg", "jpeg", "png"]}
            resolutionType={"less"}
            resolutionHeight={2000}
            resolutionWidth={2000}
            // maxNumber={1}
            allowNonImageType={true}
            alt={currentObject?.name}
            requirementsText={MAIN_OBJECT_IMAGE_REQ}
            classNameImgWrapper={styles.bookingPhoto}
          />
        </div>
        <div className={styles.photoWrapper}>
          <h3 className={styles.contentTitle}>Фото</h3>
          <ImageComponent
            newImage={newPhoto}
            image={photo}
            setNewImage={setNewPhoto}
            setImage={setPhoto}
            setError={setNewPhotoError}
            errText={newPhotoError}
            noImageIcon={<Home2Icon />}
            maxFileSize={2000000}
            acceptType={["jpg", "jpeg", "png"]}
            resolutionType={"less"}
            resolutionHeight={2000}
            resolutionWidth={2000}
            // maxNumber={1}
            alt={currentObject?.name}
            requirementsText={MAIN_OBJECT_IMAGE_REQ}
            classNameImgWrapper={styles.photo}
          />
        </div>
        <div className={styles.fieldsWrapper}>
          <h3 className={styles.contentTitle}>Объект</h3>
          <div className={styles.fields}>
            <div className={styles.fieldsItem}>
              <Input
                value={objectName}
                title="Название объекта*"
                placeholder="Введите название объекта"
                type={InputType.TEXT}
                onChange={setObjectName}
                errText={objectNameError}
                onBlur={setObjectNameTouched}
                requirementsText={OBJECT_NAME_REQ}
                customBanSymbols={banDoubleSpace}
              />
              <SelectComponent
                title="Тип объекта размещения*"
                optionsList={objectTypes.optionsList}
                currentValue={objectType}
                setSelecValue={setObjectType}
                errText={objectTypeError}
                isLoading={objectTypes.optionsList.length === 0}
                onBlur={setObjectTypeTouched}
              />
            </div>
            <div className={styles.fieldsItem}>
              <SelectComponent
                title="Страна*"
                optionsList={countriesList}
                currentValue={country}
                setSelecValue={setCountry}
                errText={countryError}
                isLoading={!countriesList}
                onBlur={setCountryTouched}
                isSearchable
              />
              <Input
                value={region}
                title="Административное деление*"
                placeholder="Введите административное деление"
                type={InputType.TEXT}
                onChange={setRegion}
                errText={regionError}
                onBlur={setRegionTouched}
                requirementsText={REGION_REQ}
                customBanSymbols={banDoubleSpace}
              />
              <Input
                value={locality}
                title="Населенный пункт*"
                placeholder="Введите населенный пункт"
                type={InputType.TEXT}
                onChange={setLocality}
                errText={localityError}
                onBlur={setLocalityTouched}
                requirementsText={LOCALITY_REQ}
                customBanSymbols={banDoubleSpace}
              />
              <Input
                value={address}
                title="Адрес*"
                placeholder="Введите адрес"
                type={InputType.TEXT}
                onChange={setAddress}
                errText={addressError}
                onBlur={setAddressTouched}
                requirementsText={ADDRESS_REQ}
                customBanSymbols={banDoubleSpace}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.btnsContainer}>
        {currentTarif!==TarifTypes.FREE &&
        <div className={styles.btnsWrapBooking}>
          <Button
            title="Открыть страницу бронирования"
            type={ButtonType.SECONDARY_SMALL}
            path={bookingLink}
            targetBlank
            wrapperClassName={styles.openBookingBtnWrap}
            className={styles.openBookingBtn}
          />
          <Button
            title={<CopyIcon />}
            className={styles.copyBtn}
            type={ButtonType.SMALL}
            onClick={copyLinkBtn}
          />
        </div>}
        <div className={styles.btnsWrapMain}>
          <Button
            title="Сохранить"
            type={ButtonType.PRIMARY_SMALL}
            // confirmWindowText="Сохранить изменения?"
            onClick={onSaveBtnClick}
            disabled={!isValid || !isFieldsChanged}
            className={styles.mainBtn}
          />
          <Button
            title="Отмена"
            type={ButtonType.SECONDARY_SMALL}
            path={RoutesList.objectManagement.fullPath}
            className={styles.mainBtn}
          />
        </div>
      </div>
    </>
  );
};

export default BasicSettings;
