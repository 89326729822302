import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import {format} from "date-fns"
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon, NotificationIcon } from "../../assets/icons";
import { BookingSelectors, getBookingList, getChessObjects } from "../../redux/reducers/bookingSlice";
import { useClickOutside } from "../../utils/functions";
import { getBookingStatusName } from "../Booking/functions";

import styles from "./NotificationSign.module.scss";

type NoteProp = {
  color?:string
}

const NotificationSign = ({color}:NoteProp) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBookingList({}))    
  }, []);
  useEffect(() => {
    dispatch(getChessObjects({}))    
  }, []);
  const objects = useSelector(BookingSelectors.getChessObjects);
  const bookingArray = useSelector(BookingSelectors.getBookingList);  
  const filteredList = bookingArray.filter(item=> !item.viewed).sort((a,b)=>{  //сортируем по дате создания
    const f = new Date(a.created_at).getTime();  
    const s = new Date(b.created_at).getTime();        
  return s-f
  }) 
  const countNotViewed = filteredList.length
  const displayCount = countNotViewed>99?"99+" : countNotViewed

  const [isOpenList, setIsOpenList] = useState<boolean>(false)
  const openList = (e:any) => {
    setIsOpenList(!isOpenList)
  }
  const listRef = useRef(null);
  const btnRef = useRef(null);
  
  useClickOutside(listRef, btnRef, () => {    
    setIsOpenList(false)
  }); 
  const countTitle = countNotViewed%10 ===1? "новое уведомление"
  : countNotViewed%10<5 ? "новых уведомления"
  : "новых уведомлений"
  
  return(
    <div className={styles.wrap} ref={listRef}>
      <div className={styles.container} onClick={(e)=>openList(e)} ref={btnRef}>
        <div >
          <NotificationIcon fill={color}/>
        </div>
        {countNotViewed>0 &&
        <div className={styles.count} >
          {displayCount}
        </div>}
      </div>
      {isOpenList && countNotViewed>0 &&
      <div className={styles.popup_container}>
        <div className={styles.popup_header}>
          <div>У вас {countNotViewed}&ensp;{countTitle}</div>
          <div ref={btnRef} onClick={()=>setIsOpenList(false)}>
            <CloseIcon width='20' height='20' fill='#0A3A86'/>
          </div>
        </div>
        <div className={styles.popup_list}>
          {filteredList.map(item=> {
            let objectName:string="";
            objects&&objects.map(obj => {
              if (obj.rental_objects.length>0){
                const ind = obj.rental_objects.find(rent=> rent.id === item.rental_object)
                if(ind) objectName=ind.name
              }             
            });
            const statusName = getBookingStatusName(item.status)
            const bookingClassname = item.status==="new"? "new"
              : item.status==="awt"? "await"
              : +item.total===+item.paid? "full_paid" 
              : +item.paid>0? "part_paid"
              : "not_paid";
            return(
              <div className={styles.item_wrap} key={item.id}>
                <div className={styles.item_name}>{objectName}</div>
                <div className={classNames(styles.item_status, styles[bookingClassname])}>
                  {statusName}
                </div>
                <div className={styles.item_main}>
                  {item.last_name} {item.first_name} {item.second_name}, &ensp;
                  { format(item.check_in_date, "dd.MM.yyyy")}-{format(item.check_out_date, "dd.MM.yyyy")},&ensp;
                  {item.total} BYN
                </div>
                <div className={styles.item_create}>
                  {format(item.created_at, "dd.MM.yyyy")} в {format(item.created_at, "H:m")}
                </div>
              </div>
            )})
          }
        </div>
      </div>}    
    </div>
  )
}

export default NotificationSign;