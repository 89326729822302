import React, { FC, MutableRefObject, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import {addDays} from "date-fns";

import styles from "./Prices.module.scss";
import Button from "../../../../components/Button";
import { EditIcon } from "../../../../assets/icons";
import {
  ButtonType,
  FieldTypes,
  InputType,
  OptionsListType,
  TabsNames,
} from "../../../../utils/@globalTypes";
import { RoutesList } from "../../../../App";
import { useParams } from "react-router-dom";
import Input from "../../../../components/Input";
import SelectComponentV2 from "../../../../components/SelectComponentV2";
import {
  getFormattedDateWithMonthAndDay,
  getCurrentYearMonthOptions,
  setFieldErrorText,
} from "../../../../utils/functions";
import {
  addCertainDayPricesStatus,
  PricesSelectors,
  setActiveCertainPriceId,
  updatePrices,
} from "../../../../redux/reducers/pricesSlice";
import { RentalObjectSelectors } from "../../../../redux/reducers/rentalObjectSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  CertainDayPriceType,
  PriceType,
  ResponseCertainDayPriceTypeList,
  ResponsePriceType,
} from "../../../../redux/types/pricesTypes";
import CertainDayPricesCard from "../../../../components/CertainDayPricesCard";
import { percentageMask, priceMask } from "../../../../utils/constants";
import CheckerboardPrice from "../../../../components/CheckerboardPrice";
import classNames from "classnames";
import { TariffsSelectors } from "../../../../redux/reducers/tariffsSlice";
import { TarifTypes } from "../../../../redux/types/@types";
import usePriceValidationSchema from "../../../../components/RentalPriceSettings/priceValidationSchema";
import RentalPriceSettings from "../../../../components/RentalPriceSettings";
import UnavailableContent from "../../../../components/UnavailableContent";

const Prices = () => {
  const dispatch = useDispatch();
  const { mainId, rentalId } = useParams();
  const scrollToRef = useRef<HTMLDivElement>(null);

  const currentTarif = useSelector(TariffsSelectors.getCurrentTarif)
  const currentPrices = useSelector(PricesSelectors.getPrices);
  const currentCertainDayPrices = useSelector(PricesSelectors.getCertainDayPrices);
  const certainDayPriceStatuses = useSelector(PricesSelectors.getCertainDayPriceStatuses);
  const activeCertainPrice = useSelector(PricesSelectors.getActiveCertainPriceId);  

  const [certainDayPricesList, setCertainDayPricesList] = useState<ResponseCertainDayPriceTypeList>(
    []
  );
  const [newCertainDayPricesList, setNewCertainDayPricesList] =
    useState<ResponseCertainDayPriceTypeList>([]);

  const [isSave, setIsSave] = useState(false);

  const onAddCertianDayPriceClick = () => {
    if (rentalId) {
      const randomId = Math.random() + Math.random();

      dispatch(
        addCertainDayPricesStatus({
          id: randomId,
          isNew: true,
          isLoading: false,
          isSuccess: false,
        })
      );

      setNewCertainDayPricesList((prevState) => [
        ...prevState,
        {
          id: randomId,
          start_date: "",
          end_date: "",
          price: "",
          rental_object: +rentalId,
        },
      ]);
    }
  };

  const setDate =
    (set: React.Dispatch<React.SetStateAction<string>>, index: 0 | 1) => (value: string) => {
      set((prevState) => {
        const str = prevState.split("-");
        return index === 0
          ? getFormattedDateWithMonthAndDay(+value, "XX")
          : getFormattedDateWithMonthAndDay(+str[0], +value);
      });
    };  

  useLayoutEffect(() => {
    setCertainDayPricesList(currentCertainDayPrices);
  }, [currentCertainDayPrices]);

  useEffect( () => {
    // скролл к искомой строке с настройками цен на даты
    if( scrollToRef.current ) {
      scrollToRef.current.scrollIntoView({
        block:"center",
        behavior:"smooth"  
    });
    setTimeout(() => {    
      dispatch(setActiveCertainPriceId(null))
    }, 3500)
  }
}, [activeCertainPrice]);

  return currentTarif===TarifTypes.FREE
  ? (
    <UnavailableContent/>
  ) : rentalId ? (    
    <>
      <RentalPriceSettings prices = {currentPrices}/>      

      {(currentTarif===TarifTypes.PRO || currentTarif===TarifTypes.PRO_PLUS) && 
      <><h3 className={styles.contentTitle}>Календарь действующих цен</h3>
      <div className={styles.chessBlock}>
        <CheckerboardPrice/>
      </div>
      </>}

      <h3 className={styles.contentTitle}>Настройка цены на определенные даты (по датам заезда)</h3>
      <Button           
        title="Добавить"
        type={ButtonType.PRIMARY_SMALL}
        onClick={onAddCertianDayPriceClick}
        className={styles.addPriceBtn}
      />
      
      {[ ...newCertainDayPricesList, ...certainDayPricesList].length > 0 && (
        <div className={styles.certainList}>
          {[ ...newCertainDayPricesList]    // Добавление новой настройки вверху списка
            .map((item) => {
            const status = certainDayPriceStatuses.find((status) => status.id === item.id);
            return status ? (
              <CertainDayPricesCard
                rentalId={+rentalId}
                key={item.id}
                data={item}
                status={status}
                setCertainDayPricesList={setNewCertainDayPricesList}                
              />
            ) : null;
          })}
          {[ ...certainDayPricesList]     //Список действующих настроек цен
            .filter((item)=> addDays(new Date(item.end_date),1)> new Date()) // не отображаем настройки на прошедшие даты
            .sort((a, b)=> new Date(b.start_date).getTime() - new Date(a.start_date).getTime())  //сортируем по убыванию дат
            .map((item) => {
            const status = certainDayPriceStatuses.find((status) => status.id === item.id);
            const isAnimate = activeCertainPrice === item.id ? true: false
            return status ? (
              <div key={item.id} ref={ activeCertainPrice === item.id ? scrollToRef : null } 
              className={classNames(styles.active, {
                [styles.animate]:isAnimate
              })}
              >
                <CertainDayPricesCard
                  rentalId={+rentalId}
                  key={item.id}
                  data={item}
                  status={status}
                  setCertainDayPricesList={setNewCertainDayPricesList}                  
              />
              </div>
            ) : null;
          })}  
         
        </div>
      )}
      
    </>
  ) : null;
};

export default Prices;